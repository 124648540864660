// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@pancakeswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: 56,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    defaultProvider: 'https://bscrpc.com',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WAVAX: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      FUSDT: ['0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 18], // This is actually usdc on mainnet not fusdt
      // BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      // ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      // SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      USDT: ['0x55d398326f99059fF775485246999027B3197955', 18],
      DAI: ['0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18],
      USDC: ['0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 18],
      JOE: ['0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd', 18],
      'wAVAX': ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      // '2OMB': ['0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE', 18],
      // '2OMB-2SHARES LP': ['0xd9B5f00d183df52D717046521152303129F088DD', 18],
      // '2OMB-WAVAX LP': ['0xbdC7DFb7B88183e87f003ca6B5a2F81202343478',18],
      // '2SHARES-WAVAX LP': ['0x6398ACBBAB2561553a9e458Ab67dCFbD58944e52',18],
      '2SHARES': ['0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca', 18],
      'GLOBA-WBNB LP': ['0x2506d76aAB21e8C5d0C3268A38EDa3d8D345209b',18],
      'GSHARES-WBNB LP': ['0x00C6fF0FE548C9E39E4464fF4Ca548324D6bc680',18],
      'GLOBA-GSHARES LP': ['0xd352daC95a91AfeFb112DBBB3463ccfA5EC15b65',18],
      'GSHARES': ['0xC0261b8e957497350fD36aF369408265326a1290', 18],
      'USDT-AVAX-LP': ['0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b', 18],
      'TOMB-AVAX-LP': ['0x2506d76aAB21e8C5d0C3268A38EDa3d8D345209b', 18],
      'TSHARE-AVAX-LP': ['0x00C6fF0FE548C9E39E4464fF4Ca548324D6bc680', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  // Tomb2sharesRewardPool: {
  //   name: 'Earn GLOBA by staking 2SHARES',
  //   poolId: 6,
  //   sectionInUI: 0,
  //   contract: 'Tomb2ShareRewardPool',
  //   depositTokenName: '2SHARES',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '7500x',
  //   site: "https://2omb.finance",
  //   buyLink: 'https://spookyswap.finance/swap?outputCurrency=0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
  //   sort: 0,
  //   closedForStaking: true,
  // },
  // Tomb2sharesWftmLPRewardPool: {
  //   name: 'Earn GLOBA by staking 2SHARES-WAVAX LP',
  //   poolId: 1,
  //   sectionInUI: 0,
  //   contract: 'Tomb2SharesWftmLPRewardPool',
  //   depositTokenName: '2SHARES-WAVAX LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '6000x',
  //   site: "https://2omb.finance",
  //   buyLink: 'https://spookyswap.finance/add/AVAX/0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
  //   sort: 1,
  //   closedForStaking: true,
  // },
  // Tomb2shares2ombLPRewardPool: {
  //   name: 'Earn GLOBA by staking 2OMB-2SHARES LP',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'Tomb2Shares2ombLPRewardPool',
  //   depositTokenName: '2OMB-2SHARES LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '6000',
  //   site: "https://2omb.finance",
  //   buyLink: 'https://spookyswap.finance/add/0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae/0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // Tomb2ombWftmLPRewardPool: {
  //   name: 'Earn GLOBA by staking 2OMB-WAVAX LP',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'Tomb2ombWftmLPRewardPool',
  //   depositTokenName: '2OMB-WAVAX LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '6000x',
  //   site: "https://2omb.finance",
  //   buyLink: 'https://spookyswap.finance/add/AVAX/0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // Tomb2ombRewardPool: {
  //   name: 'Earn GLOBA by staking 2OMB',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'Tomb2ombRewardPool',
  //   depositTokenName: '2OMB',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x',
  //   site: 'https://2omb.finance',
  //   buyLink: 'https://spookyswap.finance/swap?outputCurrency=0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae',
  //   sort: 4,
  //   closedForStaking: true,
  // },



  // GlobaWavaxGenesisRewardPool: {
  //   name: 'Earn GLOBA by staking WAVAX',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'GlobaWavaxGenesisRewardPool',
  //   depositTokenName: 'WAVAX',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '10000x (1% Deposit Fee)',
  //   poolStartUnixtimestamp: 1646991000,
  //   site: 'https://www.avax.network/',
  //   buyLink: 'https://traderjoexyz.com/trade?outputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83#/',
  //   sort: 0,
  //   closedForStaking: false,
  // },
  // GlobaUsdtGenesisRewardPool: {
  //   name: 'Earn GLOBA by staking USDT',
  //   poolId: 1,
  //   sectionInUI: 0,
  //   contract: 'GlobaUsdtGenesisRewardPool',
  //   depositTokenName: 'USDT',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x (1% Deposit Fee)',
  //   poolStartUnixtimestamp: 1646991000,
  //   site: 'https://tether.to/en/',
  //   buyLink: 'https://traderjoexyz.com/trade?outputCurrency=0xc7198437980c041c805a1edcba50c1ce5db95118#/',
  //   sort: 1,
  //   closedForStaking: false,
  // },
  // GlobaUsdcGenesisRewardPool: {
  //   name: 'Earn GLOBA by staking USDC',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'GlobaUsdcGenesisRewardPool',
  //   depositTokenName: 'USDC',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x (1% Deposit Fee)',
  //   poolStartUnixtimestamp: 1646991000,
  //   site: 'https://www.centre.io/',
  //   buyLink: 'https://traderjoexyz.com/trade?outputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664#/',
  //   sort: 2,
  //   closedForStaking: false,
  // },

  // GlobaJoeGenesisRewardPool: {
  //   name: 'Earn GLOBA by staking JOE',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'GlobaJoeGenesisRewardPool',
  //   depositTokenName: 'JOE',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x (1% Deposit Fee)',
  //   poolStartUnixtimestamp: 1646991000,
  //   site: 'https://traderjoexyz.com/home#/',
  //   buyLink: 'https://traderjoexyz.com/trade?outputCurrency=0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd#/',
  //   sort: 4,
  //   closedForStaking: false,
  // },



  // GlobaAvaxLpGlobaRewardPool: {
  //   name: 'Earn GLOBA by GLOBA-WAVAX LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'GlobaAvaxLpGlobaRewardPool',
  //   depositTokenName: 'GLOBA-WAVAX LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '10000',
  //   poolStartUnixtimestamp: 1646776800,
  //   buyLink: 'https://traderjoexyz.com/pool/avax/0x43C94d33De26d5598d1A9cA03EcA431163ba68Fc#/',
  //   site: '',
  //   sort: 4,
  //   closedForStaking: false,
  // },

  // OldGlobaAvaxLpGlobaRewardPool: {
  //   name: 'Earn GLOBA by GLOBA-WAVAX LP(2)',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'OldGlobaAvaxLpGlobaRewardPool',
  //   depositTokenName: 'GLOBA-WAVAX LP',
  //   earnTokenName: 'GLOBA',
  //   finished: true,
  //   multiplier: '10000',
  //   poolStartUnixtimestamp: 1646820000,
  //   buyLink: 'https://traderjoexyz.com/pool/avax/0x43C94d33De26d5598d1A9cA03EcA431163ba68Fc#/',
  //   site: '',
  //   sort: 5,
  //   closedForStaking: true,
  // },


  GlobaAvaxLPGShareRewardPool: {
    name: 'Earn GSHARES by GLOBA-WBNB LP',
    poolId: 0,
    sectionInUI: 2,
    contract: 'GlobaAvaxLPGShareRewardPool',
    depositTokenName: 'GLOBA-WBNB LP',
    earnTokenName: 'GSHARES',
    finished: false,
    multiplier: '4000x',
    poolStartUnixtimestamp: 1646992800,
    buyLink: 'https://pancakeswap.finance/add/BNB/0x43C94d33De26d5598d1A9cA03EcA431163ba68Fc',
    site: '/',
    sort: 5,
    closedForStaking: false,
  },
  GshareAvaxLPGShareRewardPool: {
    name: 'Earn GSHARES by GSHARES-WBNB LP',
    poolId: 1,
    sectionInUI: 2,
    contract: 'GshareAvaxLPGShareRewardPool',
    depositTokenName: 'GSHARES-WBNB LP',
    earnTokenName: 'GSHARES',
    finished: false,
    multiplier: '6000x',
    poolStartUnixtimestamp: 1646992800,
    buyLink: 'https://pancakeswap.finance/add/BNB/0xC0261b8e957497350fD36aF369408265326a1290',
    site: '/',
    sort: 6,
    closedForStaking: false,
  },
  GlobaWAVAXRebates: {
    name: 'Bond WBNB, earn GLOBA',
    poolId: 0,
    sectionInUI: 3,
    contract: 'GlobaRebate',
    depositTokenName: 'WBNB',
    earnTokenName: 'GLOBA',
    finished: false,
    multiplier: '5000x',
    poolStartUnixtimestamp: 1647000000,
    buyLink: '',
    site: '',
    sort: 0,
    closedForStaking: false,
  },
  GlobaDAIRebates: {
    name: 'Bond DAI, earn GLOBA',
    poolId: 4,
    sectionInUI: 3,
    contract: 'GlobaRebate',
    depositTokenName: 'DAI',
    earnTokenName: 'GLOBA',
    finished: false,
    multiplier: '5000x',
    poolStartUnixtimestamp: 1647000000,
    buyLink: '',
    site: '',
    sort: 1,
    closedForStaking: false,
  },
  GlobaGSHARESRebates: {
    name: 'Bond GSHARES, earn GLOBA',
    poolId: 1,
    sectionInUI: 3,
    contract: 'GlobaRebate',
    depositTokenName: 'GSHARES',
    earnTokenName: 'GLOBA',
    finished: false,
    multiplier: '5000x',
    poolStartUnixtimestamp: 1647000000,
    buyLink: '',
    site: '',
    sort: 3,
    closedForStaking: false,
  },
  // GlobaGSHARESAVAXRebates: {
  //   name: 'Bond GSHARES-WBNB LP, earn GLOBA',
  //   poolId: 2,
  //   sectionInUI: 3,
  //   contract: 'GlobaRebate',
  //   depositTokenName: 'GSHARES-WBNB LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x',
  //   poolStartUnixtimestamp: 1647000000,
  //   buyLink: '',
  //   site: '',
  //   sort: 4,
  //   closedForStaking: false,
  // },
  // GlobaGLOBAWAVAXRebates: {
  //   name: 'Bond GLOBA-WBNB LP, earn GLOBA',
  //   poolId: 3,
  //   sectionInUI: 3,
  //   contract: 'GlobaRebate',
  //   depositTokenName: 'GLOBA-WBNB LP',
  //   earnTokenName: 'GLOBA',
  //   finished: false,
  //   multiplier: '5000x',
  //   poolStartUnixtimestamp: 1647000000,
  //   buyLink: '',
  //   site: '',
  //   sort: 5,
  //   closedForStaking: false,
  // },

};

export default configurations['production'];
